<template>
  <div>
    <div class="bg-splash">
      <div class="form modal-window">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-title">Заявка отправлена</div>
        <div class="modal-text">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: [
    'text',
  ],

  methods: {

    closeModal: function () {
      this.$emit('close');
    },

  },

}
</script>

<style lang="scss" scoped>
@import "./../styles/variables";

$input_height: 50px;

.form {
  width: 944px;
}


@media screen and (max-width: 1023px) {
  .form {
    max-width: 840px;
    width: 100%;
  }
}
</style>